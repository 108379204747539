import { API_URL } from './constant';

async function client(endpoint, { body, token, headers: customHeaders, ...customConfig } = {}) {
  const config = {
    method: body ? 'POST' : 'GET',
    headers: {
      Authorization: token ? `Token ${token}` : undefined,
      ...customHeaders,
    },
    ...customConfig,
  };

  if (body) {
    config.body = body;
  }

  if (!customHeaders) config.headers['Content-Type'] = 'application/json';

  return window.fetch(`${API_URL}/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      window.localStorage.removeItem('token');
      // refresh the page for them
      window.location.assign(window.location);
      return Promise.reject(new Error('Please re-authenticate.'));
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    const errorMessage = await response.text();
    return Promise.reject(new Error(errorMessage));
  });
}

export default client;
