import {
  GET_PROFILES,
  GET_MY_PROFILE,
  GET_USER_PROFILE,
  EDIT_MY_PROFILE,
  AVATAR_EDITED,
  AVATAR_DELETED,
  // LIKE_POST,
  // UNLIKE_POST,
} from '../types';

const initialState = {
  profile: null,
  profiles: null,
  isMyProfile: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILES:
      return {
        ...state,
        isMyProfile: false,
        profiles: action.payload,
      };
    case GET_MY_PROFILE:
    case EDIT_MY_PROFILE:
    case AVATAR_EDITED:
    case AVATAR_DELETED:
      return {
        ...state,
        isMyProfile: true,
        profile: { ...state.profile, ...action.payload },
      };
    case GET_USER_PROFILE:
      return {
        ...state,
        isMyProfile: false,
        profile: action.payload,
      };
    // case LIKE_POST:
    // case UNLIKE_POST:
    //   return {
    //     ...state,
    //     profile: {
    //       ...state.profile,
    //       user_posts: state.profile.user_posts.map(post =>
    //         post.id === action.payload.id ? action.payload : post
    //       ),
    //     },
    //   };
    default:
      return state;
  }
};
export default reducer;
