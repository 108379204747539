import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { EN, ID } from '../../utils/language';

const Footer = ({ lang = 'ID' }) => (
  <footer className="footer">
    <div className="container my-auto">
      <div className="row">
        <div className="col-lg-6 h-100 text-center text-lg-left my-auto">
          <ul className="list-inline mb-2">
            <li className="list-inline-item">
              <Link to="/about">{lang === 'ID' ? ID.footer.about : EN.footer.about}</Link>
            </li>
            <li className="list-inline-item">&sdot;</li>
            <li className="list-inline-item">
              <Link to="/contact">{lang === 'ID' ? ID.footer.contact : EN.footer.contact}</Link>
            </li>
            <li className="list-inline-item">&sdot;</li>
            <li className="list-inline-item">
              <Link to="/terms-of-use">
                {lang === 'ID' ? ID.footer.terms_condition : EN.footer.terms_condition}
              </Link>
            </li>
            <li className="list-inline-item">&sdot;</li>
            <li className="list-inline-item">
              <Link to="/privacy-policy">
                {lang === 'ID' ? ID.footer.privacy_policy : EN.footer.privacy_policy}
              </Link>
            </li>
          </ul>
          <p className="text-muted small mb-4 mb-lg-0">&copy; Gatewee 2020. All Rights Reserved.</p>
        </div>
        <div className="col-lg-6 h-100 text-center text-lg-right my-auto">
          {/* <ul className="list-inline mb-0">
              <li className="list-inline-item mr-3">
                <a href="/">
                  <i className="fab fa-facebook-square fa-2x fa-fw"></i>
                </a>
              </li>
              <li className="list-inline-item">
                <a href="/">
                  <i className="fab fa-instagram fa-2x fa-fw"></i>
                </a>
              </li>
            </ul> */}
        </div>
      </div>
    </div>
  </footer>
);

Footer.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Footer;
