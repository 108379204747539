import { GET_ERRORS, SET_ALERT, REMOVE_ALERT } from '../types';

const initialState = {
  msg: null,
  status: null,
  alerts: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ERRORS:
      return {
        msg: action.payload.msg,
        status: action.payload.status,
      };
    case SET_ALERT:
      return { ...state, alerts: [action.payload] };
    case REMOVE_ALERT:
      return {
        ...state,
        msg: null,
        alerts: state.alerts.filter(alert => alert.id !== action.payload),
      };
    default:
      return state;
  }
};

export default reducer;
