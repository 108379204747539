import React, { useState, useEffect, Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NavigationBar from './components/layouts/Navbar/NavigationBar';
import Footer from './components/layouts/Footer';
import PrivateRoute from './components/common/PrivateRoute';
import { loadUserProfile } from './redux/actions/authActions';
import Preloader from './components/layouts/Preloader';
import Alerts from './components/layouts/Alert';

// Lazy load for each Component Page
const LandingPage = React.lazy(() => import('./pages/landing/LandingPage'));
const About = React.lazy(() => import('./pages/about/About'));
const Contact = React.lazy(() => import('./pages/contact/Contact'));
const TermCondition = React.lazy(() => import('./pages/termCondition/TermCondition'));
const PrivacyPolicy = React.lazy(() => import('./pages/privacyPolicy/PrivacyPolicy'));
const Profile = React.lazy(() => import('./pages/profile/Profile'));
const Dashboard = React.lazy(() => import('./pages/dashboard/Dashboard'));
const Login = React.lazy(() => import('./pages/auth/Login'));
const Register = React.lazy(() => import('./pages/auth/Register'));

export default function App() {
  const dispatch = useDispatch();
  const [language, setLanguage] = useState('EN');

  useEffect(() => {
    // Load user profile every component loaded for auth purpose
    dispatch(loadUserProfile());
  }, [dispatch]);

  const getLanguage = lang => {
    setLanguage(lang);
  };

  return (
    <Router>
      <Suspense fallback={Preloader()}>
        <NavigationBar getLanguage={getLanguage} />
        <Alerts />
        <Switch>
          <PrivateRoute exact path="/profile/:profileId" component={Profile} />
          <PrivateRoute exact path="/profile/:profileId/post/:postId" component={Profile} />
          <PrivateRoute exact path="/home" component={Dashboard} />
          <Route exact path="/contact" render={() => <Contact lang={language} />} />
          <Route exact path="/" render={() => <LandingPage lang={language} />} />
          <Route exact path="/about" render={() => <About lang={language} />} />
          <Route exact path="/terms-of-use" render={() => <TermCondition lang={language} />} />
          <Route exact path="/privacy-policy" render={() => <PrivacyPolicy lang={language} />} />
          <Route exact path="/login" render={() => <Login lang={language} />} />
          <Route exact path="/register" render={() => <Register lang={language} />} />
        </Switch>
        <Footer lang={language} />
      </Suspense>
    </Router>
  );
}
