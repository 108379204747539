import {
  GET_POST_ON_HOME,
  GET_NEXT_POST_BY_TRAVELER,
  GET_NEXT_POST_BY_PARTNER_TRAVELER,
  GET_ALL_COMMENT,
  POST_A_COMMENT,
  EDIT_A_COMMENT,
  DELETE_A_COMMENT,
  CREATE_POST,
  DELETE_POST,
  UPLOAD_MEDIA,
  DELETE_MEDIA,
  CLEAR_MEDIA,
  USER_BEHAVIOR,
  PUBLISH_POST_FROM_DRAFT,
  SEARCH_USERS,
  SEARCH_POSTS,
  CLEAR_SEARCH,
  LIKE_POST,
  UNLIKE_POST,
} from '../types';

const initialState = {
  traveler_post: [],
  partner_traveler_post: [],
  comment: [],
  post: [],
  users: [],
  saved_post: [],
  media: [],
  user_behavior: false,
  res_status: null,
  search: false,
  search_count: 0,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_POST:
      return {
        ...state,
        post: [action.payload, ...state.post],
      };
    case DELETE_POST:
      return {
        ...state,
        post: state.post.filter(post => post.id !== action.payload.id),
      };
    case SEARCH_USERS:
      return {
        ...state,
        search: true,
        search_count: action.payload.count,
        users: action.payload.results,
        post: [],
      };
    case SEARCH_POSTS:
      return {
        ...state,
        search: true,
        search_count: action.payload.count,
        post: action.payload.results,
        users: [],
      };
    case CLEAR_SEARCH:
      return {
        ...state,
        search: false,
        search_count: 0,
        post: [],
        users: [],
      };
    case UPLOAD_MEDIA:
      return {
        ...state,
        media: [...state.media, action.payload.id],
      };
    case PUBLISH_POST_FROM_DRAFT:
      return {
        ...state,
        post: state.post.map(post => (post.id === action.payload.id ? action.payload : post)),
      };
    case CLEAR_MEDIA:
      return {
        ...state,
        media: [],
      };
    case GET_POST_ON_HOME:
      return {
        ...state,
        traveler_post: action.payload.post_by_traveler.results,
        traveler_post_length: action.payload.post_by_traveler.count,
        partner_traveler_post: action.payload.post_by_partner_traveler.results,
        partner_traveler_post_length: action.payload.post_by_partner_traveler.count,
      };
    case GET_NEXT_POST_BY_TRAVELER:
      return {
        ...state,
        traveler_post: [...state.traveler_post, ...action.payload],
      };
    case GET_NEXT_POST_BY_PARTNER_TRAVELER:
      return {
        ...state,
        partner_traveler_post: [...state.partner_traveler_post, ...action.payload],
      };
    case GET_ALL_COMMENT:
      return {
        ...state,
        comment:
          state.comment.length === 0
            ? [...state.comment, ...action.payload]
            : [
                ...action.payload,
                ...state.comment.filter(
                  comment => !action.payload.find(newComment => comment.id === newComment.id)
                ),
              ],
      };
    case POST_A_COMMENT:
      return {
        ...state,
        comment: [...state.comment, action.payload],
      };
    case EDIT_A_COMMENT:
      return {
        ...state,
        comment: state.comment.map(commt =>
          commt.id === action.payload.id ? action.payload : commt
        ),
      };
    case DELETE_A_COMMENT:
      return {
        ...state,
        comment: state.comment.filter(commt => commt.id !== action.payload.id),
      };
    case USER_BEHAVIOR:
      return {
        ...state,
        user_behavior: !state.user_behavior,
        res_status: action.payload,
      };
    case LIKE_POST:
    case UNLIKE_POST:
      return {
        ...state,
        traveler_post: state.traveler_post.map(post =>
          post.id === action.payload.id ? action.payload : post
        ),
        partner_traveler_post: state.partner_traveler_post.map(post =>
          post.id === action.payload.id ? action.payload : post
        ),
      };
    case DELETE_MEDIA:
    default:
      return state;
  }
};

export default reducer;
