import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Form, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

function SearchBar({ searchPosts, searchUsers, choosenLang, EN, ID }) {
  const dispatch = useDispatch();
  const [search, setSearch] = useState({ label: 'Find Posts', tag: 'posts' });
  const [query, setQuery] = useState('');

  const history = useHistory();

  const encode = query => {
    const newQuery = encodeURI(query);
    return newQuery.split('%20').join('+');
  };

  const onSearch = e => {
    e.preventDefault();
    const newQuery = encode(query);

    history.push('/home');

    if (search.tag === 'posts') {
      dispatch(searchPosts(newQuery));
    } else {
      dispatch(searchUsers(newQuery));
    }
  };

  const onChange = e => {
    setQuery(e.target.value);
  };

  return (
    <>
      <Form className="my-2 my-lg-0" inline onSubmit={onSearch}>
        <div className="dropdown">
          <Button
            aria-expanded="false"
            aria-haspopup="true"
            className="mr-2"
            data-toggle="dropdown"
            id="filter"
            variant="primary"
          >
            Filter
          </Button>
          <div aria-labelledby="dropdownMenuButton" className="dropdown-menu">
            <Button
              className="dropdown-item"
              variant="link"
              onClick={() => setSearch({ label: 'Find Posts', tag: 'posts' })}
            >
              Posts
            </Button>
            <Button
              className="dropdown-item"
              variant="link"
              onClick={() => setSearch({ label: 'Find Travelers', tag: 'travelers' })}
            >
              Travelers
            </Button>
          </div>
        </div>
        <Form.Control
          aria-label="Search"
          className="mr-2"
          name="query"
          onChange={onChange}
          placeholder={search.label}
          required
          type="search"
          value={query}
        />
        <Button className="my-2 my-sm-0" type="submit" variant="outline-success">
          {choosenLang === 'ID' ? ID.navbar.search : EN.navbar.search}
        </Button>
      </Form>
    </>
  );
}

SearchBar.propTypes = {
  EN: PropTypes.object,
  ID: PropTypes.object,
  searchPosts: PropTypes.func,
  searchUsers: PropTypes.func,
  choosenLang: PropTypes.string,
};

SearchBar.defaultProps = {
  EN: undefined,
  ID: undefined,
  searchPosts: undefined,
  searchUsers: undefined,
  choosenLang: '',
};

export default SearchBar;
