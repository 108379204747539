// Auth types
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
// Profile types
export const GET_MY_PROFILE = 'GET_MY_PROFILE';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const AVATAR_EDITED = 'AVATAR_EDITED';
export const AVATAR_DELETED = 'REMOVE_AVATAR';
export const EDIT_MY_PROFILE = 'EDIT_MY_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
// Post types
export const CREATE_POST = 'CREATE_POST';
export const DELETE_POST = 'DELETE_POST';
export const SEARCH_POSTS = 'SEARCH_POSTS';
export const SEARCH_USERS = 'SEARCH_USERS';
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const PUBLISH_POST_FROM_DRAFT = 'PUBLISH_POST_FROM_DRAFT';
export const USER_BEHAVIOR = 'USER_BEHAVIOR';
export const UPLOAD_MEDIA = 'UPLOAD_MEDIA';
export const DELETE_MEDIA = 'DELETE_MEDIA';
export const CLEAR_MEDIA = 'CLEAR_MEDIA';
export const GET_ALL_COMMENT = 'GET_ALL_COMMENT';
export const POST_A_COMMENT = 'POST_A_COMMENT';
export const EDIT_A_COMMENT = 'EDIT_A_COMMENT';
export const DELETE_A_COMMENT = 'DELETE_A_COMMENT';
export const GET_POST_ON_HOME = 'GET_POST_ON_HOME';
export const GET_NEXT_POST_BY_PARTNER_TRAVELER = 'GET_NEXT_POST_BY_PARTNER_TRAVELER';
export const GET_NEXT_POST_BY_TRAVELER = 'GET_NEXT_POST_BY_TRAVELER';
export const LIKE_POST = 'LIKE_POST';
export const UNLIKE_POST = 'UNLIKE_POST';
// Messeges types
export const GET_ERRORS = 'GET_ERRORS';
export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
// General types
export const GET_LANDING = 'GET_LANDING';
export const MARK_NOTIFICATIONS_READ = 'MARK_NOTIFICATIONS_READ';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const UI_LOADING = 'UI_LOADING';
export const UI_LOADED = 'UI_LOADED';
