import { combineReducers } from 'redux';
import landing from './landingReducer';
import auth from './authReducer';
import errors from './errorReducer';
import profile from './profileReducer';
import post from './postReducer';

export default combineReducers({
  auth,
  landing,
  errors,
  profile,
  post,
});
