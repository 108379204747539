import { returnErrors, setAlert } from './errorActions';

import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  LOGIN_FAIL,
  USER_LOADED,
  USER_LOADING,
  AUTH_ERROR,
  REGISTER_SUCCESS,
  REGISTER_FAIL,
} from '../types';
import client from '../../utils/api-client';

// LOAD USER PROFILE
export const loadUserProfile = () => dispatch => {
  const token = localStorage.getItem('token');
  dispatch({ type: USER_LOADING });

  client('profile/me', { token }).then(
    data => {
      dispatch({
        type: USER_LOADED,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 404));
      dispatch({
        type: AUTH_ERROR,
      });
    }
  );
};

// REGISTER USER
export const register = (username, email, password, confirm_password) => dispatch => {
  // Request body
  const body = JSON.stringify({
    username,
    email,
    password,
    confirm_password,
  });

  client('auth/signup', { body }).then(
    () => {
      dispatch({
        type: REGISTER_SUCCESS,
      });
      dispatch(setAlert('Register success', 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 403));
      dispatch({
        type: REGISTER_FAIL,
      });
    }
  );
};

// LOGIN USER
export const login = (username, password) => dispatch => {
  // Request body
  const body = JSON.stringify({ username, password });

  client('auth/login', { body }).then(
    data => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 404));
      dispatch({
        type: LOGIN_FAIL,
      });
    }
  );
};

// LOGOUT USER
export const logout = () => dispatch => {
  const token = localStorage.getItem('token');

  client('auth/logout', { method: 'POST', token }).then(
    () => {
      dispatch({
        type: LOGOUT_SUCCESS,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};
