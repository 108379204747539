import {
  UI_LOADING,
  UI_LOADED,
  GET_LANDING,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_READ,
} from '../types';

const initialState = {
  landing: null,
  isLoading: false,
  notifications: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case UI_LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case UI_LOADED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_LANDING:
      return {
        ...state,
        landing: action.payload,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case MARK_NOTIFICATIONS_READ:
      // state.notifications.results.forEach((notif) => (notif.read = true));
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default reducer;
