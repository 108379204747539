import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import flagID from '../../../images/flag-id.png';
import flagEN from '../../../images/flag-en.png';
import { Button } from 'react-bootstrap';

const GuestBar = ({ changeLanguage, choosenLang, EN, ID }) => (
  <>
    <ul className="list-unstyled components">
      <li>
        <a
          aria-expanded="false"
          className="dropdown-toggle nav-link"
          data-toggle="collapse"
          href="#pageSubmenu"
        >
          {choosenLang === 'ID' ? ID.navbar.language : EN.navbar.language}
        </a>
        <ul className="collapse list-unstyled" id="pageSubmenu">
          <li>
            <Button variant="link" onClick={e => changeLanguage(e)}>
              <img alt="flag-circle" className="flag-icon" src={flagEN} />
              EN
            </Button>
          </li>
          <li>
            <Button variant="link" onClick={e => changeLanguage(e)}>
              <img alt="flag-circle" className="flag-icon" src={flagID} />
              ID
            </Button>
          </li>
        </ul>
      </li>
    </ul>
    <ul className="navbar-nav">
      <li className="nav-link px-3">
        <Link className="btn btn-info" id="dismiss" to="/login">
          {choosenLang === 'ID' ? ID.navbar.login : EN.navbar.login}
        </Link>
      </li>
      <li className="nav-link px-3">
        <Link className="btn btn-dark" id="dismiss" to="/register">
          {choosenLang === 'ID' ? ID.navbar.register : EN.navbar.register}
        </Link>
      </li>
    </ul>
  </>
);

GuestBar.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  choosenLang: PropTypes.string.isRequired,
  ID: PropTypes.object.isRequired,
  EN: PropTypes.object.isRequired,
};

export default GuestBar;
