import { returnErrors } from './errorActions';
import { GET_LANDING, GET_NOTIFICATIONS, MARK_NOTIFICATIONS_READ, USER_BEHAVIOR } from '../types';
import client from '../../utils/api-client';

// GET LANDING
export const getLanding = () => dispatch => {
  client('landing/').then(
    data => {
      dispatch({
        type: GET_LANDING,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// GET NOTIFICATIONS
export const getNotifications = () => dispatch => {
  const token = localStorage.getItem('token');

  client('notification/', { token }).then(
    data => {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// MARK NOTIFICATIONS READ
export const markNotificationsRead = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`notification/${id}/read`, { method: 'PATCH', token }).then(
    () => {
      dispatch({
        type: MARK_NOTIFICATIONS_READ,
      });
      dispatch({
        type: USER_BEHAVIOR,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};
