import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HOST } from '../../../utils/constant';
import defaultAvatar from '../../../images/default-avatar.png';
import flagID from '../../../images/flag-id.png';
import flagEN from '../../../images/flag-en.png';
import { Button } from 'react-bootstrap';

function UserBar({ isAuth, profile, changeLanguage, onLogout, choosenLang, ID, EN }) {
  return (
    <>
      {profile.photo ? (
        <img
          alt="Avatar"
          className="img-fluid rounded-circle my-auto nav-link"
          height="auto"
          src={`${HOST}${profile.photo}`}
          width="100"
        />
      ) : (
        <img
          alt="Avatar"
          className="img-fluid rounded-circle my-auto nav-link"
          height="auto"
          src={defaultAvatar}
          width="100"
        />
      )}
      <ul className="list-unstyled components">
        {isAuth ? (
          <li>
            <Link className="nav-link" to="/home">
              {choosenLang === 'ID' ? ID.navbar.home : EN.navbar.home}
            </Link>
          </li>
        ) : (
          <li>
            <a className="nav-link" href="/">
              {choosenLang === 'ID' ? ID.navbar.home : EN.navbar.home}
            </a>
          </li>
        )}
        <li>
          <Link className="nav-link" id="dismiss" to={`/profile/${profile.profile_id}`}>
            Profile
          </Link>
        </li>
        <li>
          <a
            aria-expanded="false"
            className="dropdown-toggle nav-link"
            data-toggle="collapse"
            href="#pageSubmenu"
          >
            {choosenLang === 'ID' ? ID.navbar.language : EN.navbar.language}
          </a>
          <ul className="collapse list-unstyled" id="pageSubmenu">
            <li>
              <Button variant="link" onClick={e => changeLanguage(e)}>
                <img alt="flag-circle" className="flag-icon" src={flagEN} />
                EN
              </Button>
            </li>
            <li>
              <Button variant="link" onClick={e => changeLanguage(e)}>
                <img alt="flag-circle" className="flag-icon" src={flagID} />
                ID
              </Button>
            </li>
          </ul>
        </li>
      </ul>
      <ul className="navbar-nav">
        <li className="nav-link px-3">
          <Button variant="info" id="dismiss" onClick={onLogout}>
            {choosenLang === 'ID' ? ID.navbar.logout : EN.navbar.logout}
          </Button>
        </li>
      </ul>
    </>
  );
}

UserBar.propTypes = {
  changeLanguage: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  choosenLang: PropTypes.string.isRequired,
  ID: PropTypes.object.isRequired,
  EN: PropTypes.object.isRequired,
  isAuth: PropTypes.bool.isRequired,
};

export default UserBar;
