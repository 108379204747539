import { returnErrors, setAlert } from './errorActions';
import client from '../../utils/api-client';

import {
  UI_LOADING,
  UI_LOADED,
  GET_POST_ON_HOME,
  GET_NEXT_POST_BY_TRAVELER,
  GET_NEXT_POST_BY_PARTNER_TRAVELER,
  CREATE_POST,
  DELETE_POST,
  GET_ALL_COMMENT,
  POST_A_COMMENT,
  EDIT_A_COMMENT,
  DELETE_A_COMMENT,
  UPLOAD_MEDIA,
  DELETE_MEDIA,
  CLEAR_MEDIA,
  USER_BEHAVIOR,
  PUBLISH_POST_FROM_DRAFT,
  SEARCH_POSTS,
  SEARCH_USERS,
  CLEAR_SEARCH,
  LIKE_POST,
  UNLIKE_POST,
} from '../types';

// UPLOADING MEDIA
export const uploadMedia = (media, order) => dispatch => {
  const token = localStorage.getItem('token');

  // Request body
  const body = new FormData();
  body.append('order', order);
  body.append('binary', media);

  client(`post/media/upload/${order}`, {
    body,
    token,
    headers: {},
  }).then(
    data => {
      dispatch({
        type: UPLOAD_MEDIA,
        payload: data,
      });
      dispatch(setAlert(data.message, 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 400));
    }
  );
};

// DELETE MEDIA
export const deleteMedia = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/media/${id}`, {
    method: 'DELETE',
    token,
  }).then(
    () => {
      dispatch({
        type: DELETE_MEDIA,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// CLEAR MEDIA
export const clearMedia = () => dispatch => {
  dispatch({
    type: CLEAR_MEDIA,
  });
};

// CREATE A POST
export const createPost =
  (media_ids, caption, description, hashtag, map_latitude, map_longitude, map_name, status) =>
  dispatch => {
    const token = localStorage.getItem('token');

    // Request body
    const body = JSON.stringify({
      media_ids,
      caption,
      description,
      hashtag,
      map_latitude,
      map_longitude,
      map_name,
      status,
    });

    client('post/create', {
      body,
      token,
    }).then(
      data => {
        dispatch({ type: USER_BEHAVIOR, payload: 200 });
        dispatch({
          type: CREATE_POST,
          payload: data,
        });
      },
      err => {
        dispatch(returnErrors(err.message, 403));
        dispatch(setAlert('The field may not be blank', 'danger'));
        dispatch({ type: USER_BEHAVIOR, payload: 403 });
      }
    );
  };

// PUBLISH A POST FROM DRAFT
export const publishPost =
  (id, media_ids, caption, description, hashtag, map_latitude, map_longitude, map_name, status) =>
  dispatch => {
    const token = localStorage.getItem('token');

    // Request body
    const body = JSON.stringify({
      media_ids,
      caption,
      description,
      hashtag,
      map_latitude,
      map_longitude,
      map_name,
      status,
    });

    client(`post/${id}`, {
      method: 'PATCH',
      body,
      token,
    }).then(
      data => {
        dispatch({ type: USER_BEHAVIOR, payload: 201 });
        dispatch({
          type: PUBLISH_POST_FROM_DRAFT,
          payload: data,
        });
      },
      err => {
        dispatch(returnErrors(err.message, 403));
      }
    );
  };

// DELETE POST
export const deletePost = id => dispatch => {
  const token = localStorage.getItem('token');
  client(`post/${id}`, { token, method: 'DELETE' }).then(
    data => {
      dispatch({
        type: DELETE_POST,
        payload: data,
      });
      dispatch({ type: USER_BEHAVIOR, payload: 201 });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// GET POST ON HOME
export const getHomePost = () => dispatch => {
  const token = localStorage.getItem('token');

  dispatch({ type: UI_LOADING });

  client('general/home', { token }).then(
    data => {
      dispatch({
        type: GET_POST_ON_HOME,
        payload: data,
      });
      dispatch({ type: UI_LOADED });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// GET NEXT POST BY PARTNER TRAVELER
export const nextPartnerTravelerPost = index => dispatch => {
  const token = localStorage.getItem('token');

  client(`general/home/post-by-partner-traveler?page=${index}`, { token }).then(
    data => {
      dispatch({
        type: GET_NEXT_POST_BY_PARTNER_TRAVELER,
        payload: data.results,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// GET NEXT POST BY TRAVELER
export const nextTravelerPost = index => dispatch => {
  const token = localStorage.getItem('token');

  client(`general/home/post-by-traveler?page=${index}`, { token }).then(
    data => {
      dispatch({
        type: GET_NEXT_POST_BY_TRAVELER,
        payload: data.results,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// SEARCH POST
export const searchPosts = query => dispatch => {
  const token = localStorage.getItem('token');
  dispatch({ type: UI_LOADING });

  client(`general/search/post?search=${query}`, { token }).then(
    data => {
      dispatch({
        type: SEARCH_POSTS,
        payload: data,
      });
      dispatch({ type: UI_LOADED });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// SEARCH USER
export const searchUsers = query => dispatch => {
  const token = localStorage.getItem('token');
  dispatch({ type: UI_LOADING });

  client(`general/search/user?search=${query}`, { token }).then(
    data => {
      dispatch({
        type: SEARCH_USERS,
        payload: data,
      });
      dispatch({ type: UI_LOADED });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// CLEAR SEARCH
export const clearSearch = () => dispatch => {
  dispatch({
    type: CLEAR_SEARCH,
  });
};

// GET ALL COMMENTS
export const getAllComment = post_id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${post_id}/comment`, { token }).then(
    data => {
      dispatch({
        type: GET_ALL_COMMENT,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// POST A COMMENT
export const postAComment = (post_id, description) => dispatch => {
  const token = localStorage.getItem('token');
  // Request body
  const body = JSON.stringify({ description });

  client(`post/${post_id}/comment`, { body, token }).then(
    data => {
      dispatch({
        type: POST_A_COMMENT,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// EDIT A COMMENT
export const editAComment = (post_id, comment_id, description) => dispatch => {
  const token = localStorage.getItem('token');
  // Request body
  const body = JSON.stringify({ description });

  client(`post/${post_id}/comment/${comment_id}`, { method: 'PATCH', body, token }).then(
    data => {
      dispatch({
        type: EDIT_A_COMMENT,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// DELETE A COMMENT
export const deleteAComment = (post_id, comment_id) => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${post_id}/comment/${comment_id}`, { method: 'DELETE', token }).then(
    data => {
      dispatch({
        type: DELETE_A_COMMENT,
        payload: data,
      });
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// LIKE POST
export const likePost = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${id}/like`, { method: 'PATCH', token }).then(
    data => {
      dispatch({ type: LIKE_POST, payload: data });
      dispatch(setAlert(data.message, 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// UNLIKE POST
export const unLikePost = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${id}/unlike`, { method: 'PATCH', token }).then(
    data => {
      dispatch({ type: UNLIKE_POST, payload: data });
      dispatch(setAlert(data.message, 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// SAVED POST
export const savePost = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${id}/save`, { method: 'PATCH', token }).then(
    data => {
      dispatch({ type: USER_BEHAVIOR, payload: 200 });
      dispatch(setAlert(data.message, 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};

// UNSAVED POST
export const unSavePost = id => dispatch => {
  const token = localStorage.getItem('token');

  client(`post/${id}/unsave`, { method: 'PATCH', token }).then(
    data => {
      dispatch({ type: USER_BEHAVIOR, payload: 200 });
      dispatch(setAlert(data.message, 'success'));
    },
    err => {
      dispatch(returnErrors(err.message, 403));
    }
  );
};
