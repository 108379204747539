export const EN = {
  navbar: {
    login: "Log in",
    register: "Register",
    logout: "Log out",
    search: "Search",
    home: "Home",
    language: "Language",
  },
  landing: {
    how_work: "How Gatewee Work",
    desc: [],
    testimony: "Traveler Testimonials",
  },
  footer: {
    about: "About",
    contact: "Contact",
    terms_condition: "Terms of Use",
    privacy_policy: "Privacy Policy",
  },
  register: {
    subheader: "Join with us and share your experiences",
    agreement: ["I agree to the ", "terms of use", " and ", "privacy policy"],
    button: "Register",
  },
  login: {
    subheader: "Log in and share your experiences",
    forgotPass: "Forgot your password?",
    statement: "New to Gatewee?",
    btnSignin: "Login",
    btnSignup: "Register",
  },
};

export const ID = {
  navbar: {
    login: "Masuk",
    register: "Daftar",
    logout: "Keluar",
    search: "Cari",
    home: "Beranda",
    language: "Bahasa",
  },
  landing: {
    how_work: "Bagaimana Gatewee Bekerja",
    desc: [],
    testimony: "Testimoni Wisatawan",
  },
  footer: {
    about: "Tentang",
    contact: "Kontak",
    terms_condition: "Ketentuan Penggunaan",
    privacy_policy: "Kebijakan Privasi",
  },
  register: {
    subheader: "Bergabunglah bersama kami dan bagikan pengalaman Anda",
    agreement: ['Saya setuju dengan ', 'Ketentuan Penggunaan', ' dan ', 'Kebijakan Privasi'],
    button: "Daftar",
  },
  login: {
    subheader: "Masuk dan bagikan pengalaman Anda",
    forgotPass: "Lupa password Anda?",
    statement: "Baru di Gatewee?",
    btnSignin: "Masuk",
    btnSignup: "Daftar",
  },
};
