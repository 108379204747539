import React, { forwardRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { Dropdown, Button, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { markNotificationsRead, getNotifications } from '../../redux/actions/landingActions';

const NotifIcon = forwardRef(({ onClick, children }, ref) => (
  <Button
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    ref={ref}
    variant="link"
  >
    <i className="fas fa-bell not-icon" />
    {children}
  </Button>
));

const Notifications = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.landing.notifications);
  const userBehavior = useSelector(state => state.post.user_behavior);

  useEffect(() => {
    dispatch(getNotifications());
  }, [dispatch, userBehavior]);

  dayjs.extend(relativeTime);

  const handleMenu = () => {
    const unreadNotificationsIds = notifications.results
      .filter(notif => !notif.read)
      .map(notif => notif.id);
    markNotificationsRead(unreadNotificationsIds);
  };

  const notificationsIcon = () =>
    notifications.results.filter(notif => notif.read === false).length > 0 ? (
      <Badge variant="danger">
        {notifications.results.filter(notif => notif.read === false).length}
      </Badge>
    ) : (
      ''
    );

  const notificationsMarkup =
    notifications && notifications.count > 0 ? (
      notifications.results.map((notif, idx) => {
        const verb =
          notif.type === 'POST_LIKED'
            ? 'liked'
            : notif.type === 'USER_FOLLOWED'
            ? 'followed you'
            : notif.type === 'FOLLOWED_POST_COMMENTED'
            ? 'Your followed post being commented'
            : 'commented on';
        const time = dayjs(notif.created_at).fromNow();
        const read = !notif.read;

        return notif.type === 'USER_FOLLOWED' ? (
          <Dropdown.Item key={idx} active={read} as={Link} to={`/profile/${notif.user.profile_id}`}>
            {notif.trigger.username} {verb} {time}
          </Dropdown.Item>
        ) : notif.type === 'FOLLOWED_POST_COMMENTED' ? (
          <Dropdown.Item
            key={idx}
            active={read}
            as={Link}
            to={`/profile/${notif.post.profile_id}/post/${notif.post.id}`}
          >
            {verb} {notif.trigger.username} {time}
          </Dropdown.Item>
        ) : (
          <Dropdown.Item
            key={idx}
            active={read}
            as={Link}
            to={`/profile/${notif.user.profile_id}/post/${notif.post.id}`}
          >
            {notif.trigger.username} {verb} your post {time}
          </Dropdown.Item>
        );
      })
    ) : (
      <Dropdown.Item href="#">You have no notifications yet</Dropdown.Item>
    );

  return (
    <Dropdown
      className="nav-item my-auto"
      onSelect={handleMenu}
      onToggle={isOpen => !isOpen && handleMenu()}
    >
      <Dropdown.Toggle as={NotifIcon} id="dropdown-basic">
        {notificationsIcon}
      </Dropdown.Toggle>
      <Dropdown.Menu alignRight>{notificationsMarkup}</Dropdown.Menu>
    </Dropdown>
  );
};

NotifIcon.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
};

NotifIcon.defaultProps = {
  onClick: undefined,
  children: null,
};

export default Notifications;
