import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { HOST } from '../../../utils/constant';
import Notifications from '../Notifications';
import defaultAvatar from '../../../images/default-avatar.png';
import SearchBar from './SearchBar';
import { Button } from 'react-bootstrap';

const UserSideBar = ({
  isAuth,
  profile,
  onLogout,
  choosenLang,
  searchPosts,
  searchUsers,
  ID,
  EN,
}) => (
  <>
    <li className="nav-item my-auto">
      <SearchBar
        EN={EN}
        ID={ID}
        choosenLang={choosenLang}
        searchPosts={searchPosts}
        searchUsers={searchUsers}
      />
    </li>
    {isAuth ? (
      <li className="nav-item my-auto">
        <Link className="link" to="/home">
          {choosenLang === 'ID' ? ID.navbar.home : EN.navbar.home}
        </Link>
      </li>
    ) : (
      <li className="nav-item my-auto">
        <Button variant="link" className="link">
          {choosenLang === 'ID' ? ID.navbar.home : EN.navbar.home}
        </Button>
      </li>
    )}
    <li className="nav-item dropdown my-auto">
      <Button
        aria-expanded="false"
        aria-haspopup="true"
        className="link dropdown-toggles"
        data-toggle="dropdown"
        variant="link"
        id="navbarDropdown"
        onClick={e => e.preventDefault()}
        role="button"
      >
        {profile.photo ? (
          <img
            alt="Avatar"
            className="img-fluid rounded-circle my-auto"
            height="auto"
            src={`${HOST}${profile.photo}`}
            width="30"
          />
        ) : (
          <img
            alt="Avatar"
            className="img-fluid rounded-circle my-auto"
            height="auto"
            src={defaultAvatar}
            width="30"
          />
        )}
      </Button>
      <div aria-labelledby="navbarDropdown" className="dropdown-menu">
        <Link className="dropdown-item" to={`/profile/${profile.profile_id}`}>
          <i className="fas fa-user" />
          <span>Profile</span>
        </Link>
        <div className="dropdown-divider" />
        <Button variant="link" className="dropdown-item" onClick={onLogout}>
          <i className="fas fa-sign-out-alt" />
          <span>{choosenLang === 'ID' ? ID.navbar.logout : EN.navbar.logout}</span>
        </Button>
      </div>
    </li>
    <Notifications />
  </>
);

UserSideBar.propTypes = {
  onLogout: PropTypes.func.isRequired,
  searchPosts: PropTypes.func.isRequired,
  searchUsers: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  choosenLang: PropTypes.string.isRequired,
  isAuth: PropTypes.bool.isRequired,
  ID: PropTypes.object.isRequired,
  EN: PropTypes.object.isRequired,
};

export default UserSideBar;
