import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const GuestSideBar = ({ choosenLang, ID, EN }) => (
  <>
    <li className="nav-item my-auto">
      <Link className="link" to="/login">
        {choosenLang === 'ID' ? ID.navbar.login : EN.navbar.login}
      </Link>
    </li>
    <li className="nav-item my-auto">
      <Link className="link" to="/register">
        {choosenLang === 'ID' ? ID.navbar.register : EN.navbar.register}
      </Link>
    </li>
  </>
);

GuestSideBar.propTypes = {
  choosenLang: PropTypes.string.isRequired,
  ID: PropTypes.object.isRequired,
  EN: PropTypes.object.isRequired,
};

export default GuestSideBar;
