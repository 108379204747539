import React from 'react';
import { useSelector } from 'react-redux';
import { Alert, Container, Row, Col } from 'react-bootstrap';

const Alerts = () => {
  const alerts = useSelector(state => state.errors.alerts);
  return alerts && alerts.length > 0
    ? alerts.map(alert => (
        <div
          style={{ position: 'absolute', top: 0, left: 0, right: 0, width: '100%', zIndex: 9999 }}
          key={alert.id}
        >
          <Container className="pt-5">
            <Row className="justify-content-center">
              <Col md={4}>
                <Alert variant={alert.type} className="text-center">
                  <i className="fas fa-info-circle mr-2" />
                  {alert.msg}
                </Alert>
              </Col>
            </Row>
          </Container>
        </div>
      ))
    : '';
};

export default Alerts;
