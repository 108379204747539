import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { logout } from '../../../redux/actions/authActions';
import { getLanding } from '../../../redux/actions/landingActions';
import { searchPosts, searchUsers } from '../../../redux/actions/postActions';
import flagID from '../../../images/flag-id.png';
import flagEN from '../../../images/flag-en.png';
import { EN, ID } from '../../../utils/language';
import GuestSideBar from './GuestSideBar';
import GuestBar from './GuestBar';
import UserBar from './UserBar';
import UserSideBar from './UserSideBar';
import SearchBar from './SearchBar';

import '../style.css';

const NavigationBar = ({ getLanguage }) => {
  const dispatch = useDispatch();
  const [choosenLang, setChoosenLang] = useState('');
  const { isAuthenticated, profile } = useSelector(state => state.auth);
  const landing = useSelector(state => state.landing.landing);

  useEffect(() => {
    dispatch(getLanding());
  }, [dispatch]);

  useEffect(() => {
    if (!landing) {
      return;
    }
    setChoosenLang(landing.language);
    getLanguage(landing.language);
  }, [getLanguage, landing]);

  const changeLanguage = e => {
    e.preventDefault();
    getLanguage(e.target.textContent);
    setChoosenLang(e.target.textContent);
  };

  const onLogout = e => {
    e.preventDefault();
    dispatch(logout());
  };

  return (
    <div className="wrapper">
      <nav className="shadow-sm" id="sidebar">
        <div className="sidebar-header">
          {isAuthenticated ? (
            <Link className="navbar-brand mx-auto" to="/home">
              <h3 id="logo">Gatewee</h3>
            </Link>
          ) : (
            <a className="navbar-brand mx-auto" href="/">
              <h3 id="logo">Gatewee</h3>
            </a>
          )}
        </div>
        {isAuthenticated ? (
          <UserBar
            EN={EN}
            ID={ID}
            changeLanguage={changeLanguage}
            choosenLang={choosenLang}
            isAuth={isAuthenticated}
            onLogout={onLogout}
            profile={profile}
          />
        ) : (
          <GuestBar EN={EN} ID={ID} changeLanguage={changeLanguage} choosenLang={choosenLang} />
        )}
      </nav>
      <div className="overlay" />
      <div id="content">
        <Navbar bg="light" className="shadow-sm" expand="lg" fixed="top">
          <Container className="align-items-center">
            {isAuthenticated ? (
              <>
                <Link className="navbar-brand mx-auto show-brand" id="logo" to="/home">
                  Gatewee
                </Link>
                <div className="show-search">
                  <SearchBar
                    EN={EN}
                    ID={ID}
                    choosenLang={choosenLang}
                    searchPosts={searchPosts}
                    searchUsers={searchUsers}
                  />
                </div>
              </>
            ) : (
              <Navbar.Brand className="mx-auto" href="/" id="logo">
                Gatewee
              </Navbar.Brand>
            )}
            <Navbar.Toggle data-toggle="collapse" id="sidebarCollapse" />
            <Navbar.Collapse>
              <Nav className="ml-auto mt-2 mt-lg-0">
                {isAuthenticated ? (
                  <UserSideBar
                    EN={EN}
                    ID={ID}
                    changeLanguage={changeLanguage}
                    choosenLang={choosenLang}
                    isAuth={isAuthenticated}
                    onLogout={onLogout}
                    profile={profile}
                    searchPosts={searchPosts}
                    searchUsers={searchUsers}
                  />
                ) : (
                  <GuestSideBar EN={EN} ID={ID} choosenLang={choosenLang} />
                )}
                {choosenLang === 'ID' ? (
                  <Nav.Item className="dropdown my-auto">
                    <Button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="link"
                      data-toggle="dropdown"
                      variant="link"
                      id="navbarDropdown"
                    >
                      <img alt="flag-circle" className="flag-icon" src={flagID} />
                      ID
                      <span className="ml-4 dropdown-toggle" />
                    </Button>
                    <div aria-labelledby="navbarDropdown" className="dropdown-menu">
                      <Button
                        variant="link"
                        className="dropdown-item"
                        href="#"
                        onClick={e => changeLanguage(e)}
                      >
                        <img alt="flag-circle" className="flag-icon" src={flagEN} />
                        EN
                      </Button>
                    </div>
                  </Nav.Item>
                ) : (
                  <Nav.Item className="dropdown my-auto">
                    <Button
                      aria-expanded="false"
                      aria-haspopup="true"
                      className="link"
                      data-toggle="dropdown"
                      variant="link"
                      id="navbarDropdown"
                    >
                      <img alt="flag-circle" className="flag-icon" src={flagEN} />
                      EN
                      <span className="ml-4 dropdown-toggle" />
                    </Button>
                    <div aria-labelledby="navbarDropdown" className="dropdown-menu">
                      <Button
                        className="dropdown-item"
                        variant="link"
                        onClick={e => changeLanguage(e)}
                      >
                        <img alt="flag-circle" className="flag-icon" src={flagID} />
                        ID
                      </Button>
                    </div>
                  </Nav.Item>
                )}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </div>
  );
};

NavigationBar.propTypes = {
  getLanguage: PropTypes.func.isRequired,
};

export default NavigationBar;
